import userApi from '@/api/user.api';
import { loginService } from "@/business/loginService";
import { messagingService } from '@/business/messagingService';
import ChangeEnterpriseComponent from "@/components/change-enterprise/ChangeEnterpriseComponent.vue";
import ReportDeferComponent from '@/components/commons/report-defer/ReportDeferComponent.vue';
import DialogComponent from "@/components/dialog/DialogComponent.vue";
import FooterComponent from "@/components/footer/FooterComponent.vue";
import NavbarComponent from "@/components/navbar/NavbarComponent.vue";
import ReportBugComponent from "@/components/report-bug/ReportBugComponent.vue";
import SnackbarComponent from "@/components/snackbar/SnackbarComponent.vue";
import '@firebase/messaging';
import { MESSAGES_TYPES } from '@colven/common-domain-lib/lib';
import 'firebase/performance'
import 'firebase/analytics';
import * as firebase from 'firebase/app';
// Add the Performance Monitoring library
import 'firebase/performance';
import { mapActions, mapMutations, mapState } from "vuex";
import HomeComponent from "./components/home/HomeComponent";
import { FirebaseConfig, NOTIFICATIONS_PAGE_SIZE, SnackbarStyle } from './constants/constants';

export default {
  name: "App",

  components: {
    "common-navbar": NavbarComponent,
    "common-footer": FooterComponent,
    HomeComponent,
    SnackbarComponent,
    DialogComponent,
    ReportBugComponent,
    ChangeEnterpriseComponent,
    ReportDeferComponent
  },

  data: () => ({
    showUpdateUI: false
  }),
  computed: {
    ...mapState("app", ["displayHeader", "displayFooter"])
  },

  async created() {
    await this.refreshToken();
    const userId = localStorage.getItem('userId')
    // notificaciones y alertas
    this.getNotifications(userId)
    // Initialize Firebasepdated upstream
    firebase.initializeApp(FirebaseConfig)
    // Initialize Analytics
    firebase.analytics()
    // Initialize Performance Monitoring and get a reference to the service
    firebase.performance()

    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        const messaging = firebase.messaging()
        messaging.getToken().then((currentToken) => {
          if (currentToken) {
            this.$store.commit('messaging/setFirebaseToken', { firebaseToken: currentToken })
            const userId = localStorage.getItem('userId')
            if (userId) {
              userApi.updateAlertConfiguration({ userId, firebaseToken: currentToken })
            }
          } else {
            // Show permission request.
            console.log('No Instance ID token available. Request permission to generate one.')
            // Show permission UI.
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err)
        })

        // Actualización de token
        messaging.onTokenRefresh((token) => {
          if (token) {
            this.$store.commit('messaging/setFirebaseToken', { firebaseToken: token })
            const userId = localStorage.getItem('userId')
            if (userId) {
              userApi.updateAlertConfiguration({ userId, firebaseToken: token })
            }
          }
        })

        /*
          Manejo de mensajes de Firebase
        */
        messaging.onMessage((payload) => {
          this.firebaseMessageHandler(payload)
        })
      } else {
        console.log('Unable to get permission to notify.')
      }
      this.alertNotificationsNeeded(permission)
    })
  },

  mounted() {
    this.$vuetify.lang.current = this.$i18n.locale
  },

  methods: {
    ...mapActions({
      showSnackbar: "snackbar/showSnackbar",
      closeSnackbar: "snackbar/closeSnackbar"
    }),
    ...mapMutations('app', [
      'setNotifications',
      'setBadgeCount',
      'addNewNotification',
      'setNewNotification',
      'setNotificationsTotalCount'
    ]),
    async refreshToken() {
      const enterpriseId = localStorage.getItem("enterpriseId");
      if (enterpriseId) {
        await loginService.refreshAndUpdateUserData(
          enterpriseId + "",
          this.$i18n
        );
      }
    },
    alertNotificationsNeeded(actualPermission) {
      if (actualPermission === 'denied' || actualPermission === 'default') {
        this.showSnackbar({ visible: true, text: this.$t('notifications.notificationsDisabled'), timeout: 9500, style: SnackbarStyle.WARNING })
      }
    },
    async getNotifications() {
      const userId = localStorage.getItem('userId')
      if (userId) {
        // badge
        messagingService.getNotReadCount(userId).then((badgeCount) => {
          this.setBadgeCount({ badgeCount })
        })
        // total
        messagingService.getNotificationsTotalCount(userId).then((totalCount) => {
          this.setNotificationsTotalCount(totalCount)
        })
        // notificaciones
        messagingService.findNotifications(NOTIFICATIONS_PAGE_SIZE, 1, userId).then(response => {
          this.setNotifications(response)
        })
      }
    },
    firebaseMessageHandler(payload) {
      const userId = localStorage.getItem('userId')
      if (userId) {
        if (payload && payload.data) {
          switch (payload.data.type) {
            case "PRODUCTS_REPORT":
              this.$store.commit('reportDefer/openReport', payload.data)
              this.addNotification(payload, userId)
              break
            // reporte diferido
            case MESSAGES_TYPES.REPORT:
              this.$store.commit('reportDefer/openReport', payload.data)
              this.addNotification(payload, userId)
              break
            // error
            case MESSAGES_TYPES.ERROR:
              this.$store.commit('snackbar/showSnackbar', {
                visible: true,
                text: payload.data.title,
                style: SnackbarStyle.ERROR
              })
              this.addNotification(payload, userId)
              break
            case MESSAGES_TYPES.INFO:
              if (payload.data.osId) {
                this.$store.commit('messaging/setOSData', {
                  id_command: payload.data.id_command,
                  status: payload.data.status,
                  os: payload.data.os,
                  osId: payload.data.osId,
                  type: payload.data.type
                })
              }

              break
            default:
              console.log(payload)
              break
          }
        }
      }
    },
    addNotification(payload, userId) {
      this.addNewNotification(payload.data)
      if (this.notificationSound) {
        this.playSound()
      }
      messagingService.getNotificationsTotalCount(userId).then((totalCount) => {
        this.setNotificationsTotalCount(totalCount)
      })
    },
  }
};
