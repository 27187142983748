import enterpriseApi from "@/api/enterprise.api"
import { ColvenTheme } from '@/constants/constants'

export const enterpriseService = (function() {
    "use strict"

    /**
     * Para obtener los datos de la empresa del usuario
     * Se utiliza en el autologin de cybermapa
     * @param {*} enterpriseId
     */
    async function getEnterpiseLoginData(enterpriseId) {
        try {
            if (enterpriseId) {
                let result = await enterpriseApi.getEnterpiseLoginData(enterpriseId)
                return result.data
            } else {
                return {
                    themes: ColvenTheme,
                    loginLogo: undefined,
                    logo: undefined,
                    loginBackgroundImage: undefined
                }
            }
            
        } catch (e) {
            console.error(e)
            return {
                themes: ColvenTheme,
                loginLogo: undefined,
                logo: undefined,
                loginBackgroundImage: undefined
            }
        }
    }

    return {
        getEnterpiseLoginData
    }
})()
