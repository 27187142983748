export default function setup() {
  Number.prototype.toFixedNumber = function (digits, base) {
    var pow = Math.pow(base || 10, digits);
    return Math.round(this * pow) / pow;
  }

  // eslint-disable-next-line no-extend-native
  Array.prototype.clean = function () {
    this.splice(0, this.length)
  }

  // eslint-disable-next-line no-extend-native
  Array.prototype.cleanAndUpdate = function (newArray) {
    this.clean()
    this.pushArray(newArray)
  }

  // eslint-disable-next-line no-extend-native
  Array.prototype.pushArray = function (newArray) {
    if (newArray) {
      newArray.forEach(element => {
        this.push(element)
      })
    }
  }
}
