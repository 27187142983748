import { NotificationType } from '@/constants/constants';
import http from '@/middleware';

/*
notification = {
  _id: '2k21jh421kj4218'
  userId: 'lgkju6o589ukjh',
  title: 'TITULO',
  text: 'TEXTO',
  type: 'REPORT',
  url: '/harvesters/current-status-report',
  key: 'djhdgaskjhwg2r3i7ur',
  show: false,
  delete: false,
  date: new Date()
*/
export const alertsBadgeMock = 8;
export const alertsTotalMock = 8;

const messagingApi = {
  // NOTIFICACIONES -----------------------------------------------------------------------------------------------------------------
  async findAll (pageSize, pageNumber, userId) {
    // Notificaciones paginadas
    return await http.get(`/api/messaging/findAll?count=${pageSize}&page=${pageNumber}&userId=${userId}`);
  },
  async findAllForType (pageSize, pageNumber, userId) {
    // Notificaciones paginadas
    return await http.get(`/api/messaging/findAllForType?count=${pageSize}&page=${pageNumber}&userId=${userId}&type=${NotificationType.PRODUCTS_REPORT.name}`);
  },
  async update (notification) {
    // Actualizar (marcar como leida/no-leida, eliminar)
    return await http.put(`/api/messaging/update`, notification);
  },
  async readAll (userId) {
    // Leer todas las notificaciones
    return await http.put(`/api/messaging/readAll?userId=${userId}`);
  },
  async deleteAll (userId) {
    // Eliminar todas las notificaciones
    return await http.put(`/api/messaging/deleteAll?userId=${userId}`,["PRODUCTS_REPORT"]);
  },
  async notReadCount (userId) {
    // La cantidad de notificaciones no leídas
    return await http.get(`/api/messaging/getBadge?userId=${userId}`);
  },
  async notReadCountForType (userId) {
    // La cantidad de notificaciones no leídas
    return await http.get(`/api/messaging/getBadgeForType?userId=${userId}&type=${NotificationType.PRODUCTS_REPORT.name}`);
  },
  async totalCount (userId) {
    let result = null;
    if (userId) {
      result = await http.get(`/api/messaging/count?userId=${userId}`);
    }
    return result;
  },
  async totalCountForType (userId) {
    let result = null;
    if (userId) {
      result = await http.get(`/api/messaging/countForType?userId=${userId}&type=${NotificationType.PRODUCTS_REPORT.name}`);
    }
    return result;
  }
};

export default messagingApi
