/* eslint-disable indent */
import enterpriseApi from "@/api/enterprise.api";
import { authService } from '@/business/auth.service';
import { loginService } from '@/business/loginService'
import i18n from "@/i18n";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "ChangeEnterpriseComponent",
  components: {},
  data: () => ({
    // para validar el formulario
    validForm: false,
    // reglas de validación
    rules: {
      required: value => value != null || `${i18n.t("required")}`
    },
    languages: [
      {
        id: "es",
        locale: "es",
        name: i18n.t("login.spanish")
      },
      {
        id: "us",
        locale: "en",
        name: i18n.t("login.english")
      },
      {
        id: "br",
        locale: "pt",
        name: i18n.t("login.brazilian")
      }
    ],
    selectedLanguage: {
      id: "es",
      locale: "es",
      name: i18n.t("login.spanish")
    },
    enterprises: [],
    selectedEnterprise: null,
    systemVersion: null
  }),
  computed: {
    /**
     * getters del store changeEnterprise
     */
    ...mapGetters({
      showDialog: "changeEnterprise/getShowDialog"
    }),
    ...mapGetters("user", [
      "getLocale",
      "getJwt",
      "getLoginLogo",
      "getLoginBackgroundImage",
      "getThemes"
    ]),
    /**
     * Src del logo de la empresa
     */
    logoSource() {
      if (this.selectedEnterprise) {
        if (this.selectedEnterprise.logo) {
          return this.selectedEnterprise.logo;
        } else {
          return require("../../assets/logo.png");
        }
      } else if (this.getLoginLogo) {
        return "data:image/png;base64," + this.getLoginLogo;
      } else {
        return require("../../assets/logo.png");
      }
    }
  },
  created() {},
  beforeDestroy() {},
  mounted() {
    const version = localStorage.getItem("systemVersion");
    if (version) {
      this.systemVersion = version;
    }
  },
  methods: {
    /**
     * actions del store snackbar
     */
    ...mapActions("snackbar", ["showSnackbar", "closeSnackbar"]),
    /**
     * actions de store reportBug
     */
    ...mapActions("changeEnterprise", ["closeChangeEnterpriseDialog"]),
    ...mapMutations("app", ["setNavBarButtons"]),
    ...mapActions("user", {
      setUserId: "setUserId",
      // setJwt: "setJwt",
      setLogo: "setLogo",
      setLoginLogo: "setLoginLogo",
      setLoginBackgroundImage: "setLoginBackgroundImage",
      setThemes: "setThemes",
      setLocale: "setLocale",
      login: "login",
      setToken: "setToken",
      setPrivileges: "setPrivileges",
      setAdmin: "setAdmin",
      setLocales: "setLocales",
      setMenu: "setMenu",
      clearSessionData: "clearSessionData"
    }),
    /**
     * Guardar
     */
    async save() {
      await authService.setEnterprise(this.selectedEnterprise.id);
      let userSession = await loginService.refreshToken(this.selectedEnterprise.id)
      if (userSession.enterprise) {
          await loginService.updateUserData(userSession, this.$i18n, true)
      }
      this.saveLanguage();
      this.cancel();
      if (this.$route.path !== "/home") {
        this.$router.push("/home");
        this.$router.go();
      } else {
        this.$router.go(0);
      }
    },
    /**
     * Si se hace click fuera del dialog, se ejecuta la misma función que el botón cancelar
     */
    clickOutside() {
      this.cancel();
    },
    /**
     * Botón cancelar
     */
    cancel() {
      this.closeChangeEnterpriseDialog();
    },
    setLanguage(lang) {
      this.selectedLanguage = lang;
    },
    saveLanguage() {
      this.$root.$i18n.locale = this.selectedLanguage.locale;
      localStorage.setItem("locale", this.selectedLanguage.locale);
      this.setLocale(this.selectedLanguage.locale);
    },
    resetFields() {
      this.getEnterprises();
      if (localStorage.locale) {
        switch (localStorage.locale) {
          case "es":
            this.selectedLanguage = this.languages[0];
            break;
          case "en":
            this.selectedLanguage = this.languages[1];
            break;
          case "pt":
            this.selectedLanguage = this.languages[2];
            break;
          default:
            this.selectedLanguage = this.languages[0];
            break;
        }
      } else {
        this.setLanguage(this.languages[0]);
      }
    },
    async getEnterprises() {
      this.enterprises = (await enterpriseApi.getAllWithLogo()).data;
      this.enterprises.forEach(e => {
        e.logo = e.logo ? "data:image/png;base64," + e.logo : undefined;
      });
      const enterpriseId = localStorage.getItem("enterpriseId");
      if (enterpriseId) {
        this.selectedEnterprise = this.enterprises.find(
          e => e.id === enterpriseId
        );
      }
    }
    // async setGeneralSettings (userSession) {
    //   const userId = userSession.user._id
    //   if (userId) {
    //     // versión del sistema
    //     const config = await generalConfigurationApi.getConfig('systemVersion', 'portalAgro')
    //     if (config && config.data && config.data.version) {
    //       localStorage.setItem('systemVersion', config.data.version)
    //     }
    //   }
    // }
  },
  watch: {
    showDialog(newShow) {
      if (newShow) {
        this.resetFields();
      }
    }
  }
};
