import deepcopy from 'deepcopy'
/*
notification = {
  _id: '2k21jh421kj4218'
  userId: 'lgkju6o589ukjh',
  title: 'TITULO',
  text: 'TEXTO',
  type: 'REPORT',
  url: '/harvesters/current-status-report',
  key: 'djhdgaskjhwg2r3i7ur',
  show: false,
  delete: false,
  date: new Date()
*/
export const state = () => ({
  dark: localStorage.getItem("dark")
    ? localStorage.getItem("dark") === "true"
    : false,
  displayHeader: false,
  displayFooter: false,
  navBarButtons: [],
  navBarSettings: [],
  notifications: [],
  badgeCount: 0,
  notificationSound: false,
  newNotification: undefined,
  notificationsTotalCount: 0
});

const getters = {
  getDark: state => {
    return state.dark;
  },
  getDisplayHeader: state => {
    return state.displayHeader;
  },
  getDisplayFooter: state => {
    return state.displayFooter;
  },
  getDisplayBreadcrumb: state => {
    return state.displayBreadcrumb;
  },
  getNavBarButtons: state => {
    return state.navBarButtons;
  },
  getNotifications: (state) => {
    return state.notifications
  },
  getNotificationsCount: (state) => {
    return state.notifications ? state.notifications.length : 0
  },
  getNotificationsTotalCount: (state) => {
    return state.notificationsTotalCount
  },
  getBadgeCount: (state) => {
    return state.badgeCount
  },
  getNotificationSound: (state) => {
    return state.notificationSound
  },
  getNewNotification: (state) => {
    return state.newNotification
  },
  isDisplayShowMoreNotifications: (state) => {
    return state.notifications.length !== state.notificationsTotalCount
  }
};

const actions = {
  setNavBarButtons({ commit }, payload) {
    commit("setNavBarButtons", payload);
  },
  switchDark({ commit }) {
    commit("switchDark");
  },
  resetAppData({ commit }) {
    commit("resetAppData");
  }
};

const mutations = {
  showHeader(state) {
    state.displayHeader = true;
  },
  showFooter(state) {
    state.displayFooter = true;
  },
  hideHeader(state) {
    state.displayHeader = false;
  },
  hideFooter(state) {
    state.displayFooter = false;
  },
  setNavBarButtons(state, payload) {
    state.navBarButtons = payload;
  },
  resetAppData(state) {
    state.navBarButtons = [];
    state.notifications = []
    state.badgeCount = 0
    state.notificationSound = true
    state.newNotification = undefined
    state.notificationsTotalCount = 0
  },
  switchDark(state) {
    state.dark = !state.dark;
    localStorage.setItem("dark", state.dark);
  },
  setNotifications(state, payload) {
    state.notifications = payload
  },
  addNewNotification(state, payload) {
    payload.show = payload.show === 'true'
    payload.delete = payload.delete === 'true'
    state.newNotification = payload
    const newNotificationArray = [payload]
    const notificationsCopy = deepcopy(state.notifications)
    Array.prototype.push.apply(newNotificationArray, notificationsCopy)
    state.notifications = newNotificationArray
    state.badgeCount++
  },
  addNotifications(state, payload) {
    const notificationsCopy = deepcopy(state.notifications)
    Array.prototype.push.apply(notificationsCopy, payload)
    state.notifications = notificationsCopy
  },
  readNotification(state, payload) {
    const notification = state.notifications.find(n => n._id === payload._id)
    notification.show = payload.value
  },
  readAllNotifications(state, payload) {
    state.notifications.forEach(notification => {
      notification.show = payload.value
    })
  },
  deleteNotification(state, payload) {
    const notification = state.notifications.find(n => n._id === payload._id)
    notification.delete = true
  },
  deleteAllNotifications(state) {
    state.notifications.forEach(notification => {
      notification.delete = true
    })
  },
  setBadgeCount(state, payload) {
    state.badgeCount = payload.badgeCount
  },
  incrementBadgeCount(state) {
    state.badgeCount++
  },
  reduceBadgeCount(state) {
    state.badgeCount--
  },
  switchNotificationSound(state) {
    state.notificationSound = !state.notificationSound
  },
  setNewNotification(state, payload) {
    state.newNotification = payload
  },
  resetNewNotification() {
    state.newNotification = undefined
  },
  setNotificationsTotalCount(state, count) {
    state.notificationsTotalCount = count
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
