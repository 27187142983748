const state = {
    entitySelectorItems: [],
    selectedItems: {},
    entitySelectorConfiguration: [],
    details: null
}

const getters = {
    getEntitySelectorItems(state) {
        return state.entitySelectorItems
    },
    getSelectedItem(state) {
        return state.selectedItems
    },
    getEntitySelectorConfiguration(state) {
        return state.entitySelectorConfiguration
    },
    getDetails(state) {
        return state.details
    }
}

const actions = {
    cleanBreadcrumb({ commit }) {
        commit('commitCleanBreadcrumb')
    },
    setEntitySelectorItems({ commit }, payload) {
        commit('commitEntitySelectorItems', payload)
    },
    setSelectedItem({ commit }, payload) {
        commit('commitSelectedItem', payload)
    },
    setEntitySelectorConfiguration({ commit }, payload) {
        commit('commitEntitySelectorConfiguration', payload)
    },
    setDetails({ commit }, payload) {
        commit('commitDetails', payload)
    },
    cleanById({ commit }, payload) {
        commit('commitCleanById', payload)
    },
    cleanSelectorsById({ commit }, payload) {
        commit('commitCleanSelectorsById', payload)
    }

}

const mutations = {
    commitEntitySelectorItems(state, entitySelectorItems) {
        const entity = state.entitySelectorItems.find((entityElement) => { return entityElement.key === entitySelectorItems.key });
        if (entity) {
            entity.items = entitySelectorItems.items;
        } else {
            state.entitySelectorItems.push(entitySelectorItems)
        }
    },
    commitSelectedItem(state, selectedItem) {
        for (const key in selectedItem) {
            state.selectedItems[key] = selectedItem[key];
        }
    },
    commitEntitySelectorConfiguration(state, entitySelectorConfiguration) {
        state.entitySelectorConfiguration.push(entitySelectorConfiguration)
    },
    commitDetails(state, details) {
        state.details = details
    },
    commitCleanBreadcrumb() {
        state.entitySelectorItems = [];
        state.selectedItems = [];
        state.entitySelectorConfiguration = [];
        state.details = null;
    },
    commitCleanSelectorsById(state, selectorKey) {
        state.entitySelectorItems.forEach(selector => {
            if (selector.key === selectorKey) {
                selector.items = [];
            }
        });
    },
    commitCleanById(state, selectorKey) {
        state.entitySelectorItems = state.entitySelectorItems.filter(selector => selector.key !== selectorKey);
        state.entitySelectorConfiguration = state.entitySelectorConfiguration.filter(selector => selector.key !== selectorKey);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}