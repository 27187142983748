import http from "@/middleware";

/**
 * Llamadas al api de enterprise
 */
const enterpriseApi = {
  /**
   * Para obtener los datos de la empresa del usuario
   * Se utiliza en el autologin de cybermapa
   * @param {*} enterpriseId
   */
  async getEnterpiseLoginData(enterpriseId) {
    const response = await http.get(
      `/api/enterprise/autologin-data/${enterpriseId}`
    );
    return response;
  },
  async getAllWithLogo() {
    let result = await http.get("/api/enterprise/all-with-logo");
    return result;
  }
};

export default enterpriseApi;
