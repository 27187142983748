import http from '@/middleware'

export const configurationApi = {
  async getConfigurationByUserAndEnterprise (userId, enterpriseId) {
    return await http.get('/api/configuration/by-user-enterprise', { params: { userId, enterpriseId } });
  },

  /**
   * Este método save se usa en el servicio configurationService
   * @param {*} userId
   * @param {*} configuration
   */
  async save (userId, configuration) {
    const response = await http.put('/api/configuration/update/' + userId, configuration);
    return response.data;
  },

  /**
   * Get de una configuración del usuario
   * @param {*} userId
   * @param {*} id
   */
  async get (userId, id) {
    const response = await http.get(`/api/configuration/one-by-id/${userId}/${id}`);
    return response.data;
  }
};

export default configurationApi
