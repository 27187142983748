import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";
import app from "./modules/app";
import breadcrumb from "./modules/breadcrumb";
import changeEnterprise from "./modules/changeEnterprise";
import dialog from "./modules/dialog";
import messaging from './modules/messaging';
import notification from "./modules/notification";
import reportDefer from './modules/report-defer';
import reportBug from "./modules/reportBug";
import snackbar from "./modules/snackbar";
import user from "./modules/user";
import secondFactor from '@colven/common-components';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    snackbar,
    app,
    dialog,
    reportBug,
    notification,
    user,
    changeEnterprise,
    breadcrumb,
    reportDefer,
    messaging,
    secondFactor
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
});
