import http from "@/middleware";

/**
 * Llamadas al API email
 */
const emailApi = {
  /**
   * Para reportar un bug por email
   * @param {*} reportBug
   */
  async sendReportBugEmail(reportBug) {
    const result = await http.post(`/api/email/report-bug`, reportBug);
    return result;
  }
};

export default emailApi;
