<template>
  <div class="homeContainer">
    HOME
  </div>
</template>

<script>
  export default {
    data: () => ({
    })
  }
</script>

<style scoped>
  .homeContainer {
    height: calc(100% - 58px) !important;
    padding-top: 58px;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
</style>