import httpServer from "@/middleware";
import "@colven/common-components";
import "@colven/common-components/dist/gestyaCommonComponents.css";
import Vue from "vue";
import CountryFlag from "vue-country-flag";
import splitPane from "vue-splitpane";
import App from "./App.vue";
import i18n from "./i18n";
import mixins from "./mixin/mixins";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import setup from "./tools/array";
import VueSnip from 'vue-snip'; 

Vue.use(VueSnip);
Vue.component("country-flag", CountryFlag);
Vue.component("split-pane", splitPane);

Vue.config.productionTip = false;
Vue.prototype.$http = httpServer;
setup();
mixins();

Vue.prototype.$eventBus = new Vue();

new Vue({
  vuetify,
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount("#app");
