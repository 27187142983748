import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'FooterComponent',
  props: {
  },
  data () {
    return {
    }
  },
  beforeMount () {
  },
  mounted () {
  },
  computed: {
    ...mapGetters({
      'getDark': 'app/getDark'
    }),
    themeIcon () {
      if (this.getDark) {
        return 'wb_sunny'
      } else {
        return 'brightness_2'
      }
    }
  },
  created () {
  },
  beforeDestroy () {
  },
  methods: {
    /**
     * action para cambiar de modo el tema
     */
    ...mapActions('app', [
      'switchDark'
    ]),
    /**
     * Acción disparada cuando se presiona el botón
     */
    changeTheme () {
      this.switchDark()
      this.$vuetify.theme.dark = this.getDark
    }
  }
}
