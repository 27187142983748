import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ReportDeferComponent',
  data () {
    return {
      visible: false,
      showText: false,
      clickStay: false
    }
  },
  created: function () {
    // TODO: modificar este watch por un watch al getter y no al estado
    // No lo cambio ahora, por cuestiones de tiempo (está así en el portal)
    this.$store.watch(state => state.reportDefer.visible, () => {
      this.visible = this.$store.state.reportDefer.visible
      this.showText = this.$store.state.reportDefer.showText
    })
    this.$store.watch(state => state.reportDefer.showText, () => {
      this.showText = this.$store.state.reportDefer.showText
    })
  },
  mounted () {
  },
  computed: {
    ...mapGetters({
      getTimeoutInstance: 'reportDefer/getTimeoutInstance',
    }),
  },
  methods: {
    ...mapActions('reportDefer', [
      'setVisible',
      'setShowText',
      'showReportDefer',
      'closeReportDefer',
      'clearTimeoutInstance'
    ]),
    overlayClick () {
      this.showText = !this.clickStay
      this.clickStay = false
    },
    stay () {
      if (this.getTimeoutInstance) {
        this.clearTimeoutInstance()
      }
      this.showText = false
      this.clickStay = true
    },
    leave () {
      this.closeReportDefer({ visible: false })
    }
  }
}
