import deepcopy from 'deepcopy'

export const state = () => ({
  notifications: [],
  badgeCount: 0,
  notificationsTotalCount: 0,
  newNotification: undefined,
  notificationSound: false
})

const actions = {
  // establecer un nuevo arreglo de notificaciones
  setNotifications ({ commit }, notifications) {
    commit('commitNotifications', { notifications })
  },
  // agregar una nueva notificacion
  addNewNotification ({ commit, getters }, notification) {
    notification.show = notification.show === 'true'
    notification.delete = notification.delete === 'true'
    const newNotifications = ([notification]).concat(getters.getNotifications)
    const newBadge = getters.getBadgeCount + 1
    commit('commitNotifications', { notifications: newNotifications })
    commit('commitBadgeCount', { badgeCount: newBadge })
  },
  // agregar un arreglo de notificaciones
  addNotifications ({ commit, getters }, notifications) {
    const newNotifications = notifications.concat(getters.getNotifications)
    commit('commitNotifications', { notifications: newNotifications })
  },
  // cambiar el valor de lectura de una notificación específica
  readNotification ({ commit, getters }, payload) {
    const notifications = deepcopy(getters.getNotifications)
    const notification = notifications.find(notification => notification._id === payload._id)
    notification.show = !notification.show
    commit('commitNotifications', { notifications })
  },
  // marcar todas las notificaciones como leídas / no leídas
  readAllNotifications ({ commit, getters }, payload) {
    const notifications = deepcopy(getters.getNotifications)
    notifications.forEach(notification => {
      notification.show = payload.value
    })
    commit('commitNotifications', { notifications })
  },
  // eliminar una notificacion
  deleteNotification ({ commit, getters }, payload) {
    const notifications = deepcopy(getters.getNotifications)
    const notification = notifications.find(notification => notification._id === payload._id)
    notification.delete = true
    const notificationsTotalCount = getters.getNotificationsTotalCount - 1
    const badgeCount = getters.getBadgeCount - 1
    commit('commitNotifications', { notifications })
    commit('commitBadgeCount', { badgeCount })
    commit('commitNotificationsTotalCount', { notificationsTotalCount })
  },
  // eliminar todas las notificaciones
  deleteAllNotifications ({ commit, getters }) {
    const notifications = deepcopy(getters.getNotifications)
    notifications.forEach(notification => {
      notification.delete = true
    })
    commit('commitNotifications', { notifications })
  },
  // establecer el badge
  setBadgeCount ({ commit }, badgeCount) {
    commit('commitBadgeCount', { badgeCount })
  },
  // incrementar el badge
  incrementBadgeCount ({ commit, getters }) {
    const badgeCount = getters.getBadgeCount + 1
    commit('commitBadgeCount', { badgeCount })
  },
  // reducir el badgeCount
  reduceBadgeCount ({ commit, getters }) {
    const badgeCount = getters.getBadgeCount - 1
    commit('commitBadgeCount', { badgeCount })
  },
  // activar / desactivar el sonido de nueva notificacion
  switchNotificationSound ({ commit, getters }) {
    const notificationSound = !getters.getNotificationSound
    commit('commitNotificationSound', { notificationSound })
  },
  // establecer la cantidad total de notificaciones
  setNotificationsTotalCount ({ commit }, notificationsTotalCount) {
    commit('commitNotificationsTotalCount', { notificationsTotalCount })
  },
  // resetear todos los datos (para el logout)
  resetNotifications ({ commit }) {
    commit('commitNotifications', { notifications: [] })
    commit('commitBadgeCount', { badgeCount: 0 })
    commit('commitNotificationsTotalCount', { notificationsTotalCount: 0 })
    commit('commitNotificationSound', { notificationSound: true })
    commit('commitNewNotification', { newNotification: undefined })
  }

}

const getters = {
  getNotifications: (state) => {
    return state.notifications
  },
  getNotificationsCount: (state) => {
    return state.notifications ? state.notifications.length : 0
  },
  getNotificationsTotalCount: (state) => {
    return state.notificationsTotalCount
  },
  getBadgeCount: (state) => {
    return state.badgeCount
  },
  getNotificationSound: (state) => {
    return state.notificationSound
  },
  getNewNotification: (state) => {
    return state.newNotification
  },
  isDisplayShowMoreNotifications: (state) => {
    return state.notifications.length !== state.notificationsTotalCount
  }
}

const mutations = {
  commitNotifications (state, { notifications }) {
    state.notifications = notifications
  },
  commitBadgeCount (state, { badgeCount }) {
    state.badgeCount = badgeCount
  },
  commitNotificationsTotalCount (state, { notificationsTotalCount }) {
    state.notificationsTotalCount = notificationsTotalCount
  },
  commitNewNotification (state, { newNotification }) {
    state.newNotification = newNotification
  },
  commitNotificationSound (state, { notificationSound }) {
    state.notificationSound = notificationSound
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
