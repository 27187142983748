import messagingApi from "@/api/messaging.api";
import { NOTIFICATIONS_PAGE_SIZE } from "@/constants/constants";
import store from "../store/store";

export const messagingService = (function() {
  "use strict";

  // NOTIFICACIONES ---------------------------------------------------------

  async function findNotifications(count, page, userId) {
    try {
      let result = await messagingApi.findAllForType(count, page, userId);
      return result.data;
    } catch (error) {
      console.log("Error finding notifications");
      return [];
    }
  }

  async function getNotificationsTotalCount(userId) {
    try {
      let result = await messagingApi.totalCountForType(userId);
      return result.data;
    } catch (error) {
      console.log("Error getting notification total count");
      return 0;
    }
  }

  async function getNotReadCount(userId) {
    try {
      let result = await messagingApi.notReadCountForType(userId);
      return result.data.count;
    } catch (error) {
      console.log("Error getting not read count nofitifacations");
      return 0;
    }
  }

  async function configureNotifications(userSession) {
    const userId = userSession.user._id;
    if (userId) {
      /*
        ACTUALIZAR TOKEN DE FIREBASE Y LENGUAJE PARA NOTIFICACIONES EXTERNAS
      */
      // userApi.updateAlertConfiguration({
      //   userId,
      //   firebaseToken: this.getFirebaseToken,
      //   lang: localStorage.locale
      // });
      /*
        NOTIFICACIONES
      */
      // badge
      getNotReadCount(userId).then(badgeCount => {
        store.dispatch("notification/setBadgeCount", badgeCount);
      });
      // total
      getNotificationsTotalCount(userId).then(totalCount => {
        store.dispatch("notification/setNotificationsTotalCount", totalCount);
      });
      // notificaciones
      findNotifications(NOTIFICATIONS_PAGE_SIZE, 1, userId).then(response => {
        store.dispatch("notification/setNotifications", response);
      });
    }
  }

  return {
    findNotifications,
    getNotificationsTotalCount,
    getNotReadCount,
    configureNotifications
  };
})();
