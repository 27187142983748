import messagingApi from '@/api/messaging.api';
import { loginService } from "@/business/loginService";
import deepcopy from 'deepcopy';
import moment from 'moment';
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { FirebaseMessageConfig, NOTIFICATIONS_PAGE_SIZE } from "../../constants/constants";

export default {
  name: "NavbarComponent",
  components: {},
  props: {},
  data() {
    return {
      username: "",
      logo: undefined,
      notificationMenu: false,
      pageNumber: 1
    };
  },
  mounted() {
    this.logo = localStorage.getItem("logo");
    this.username = localStorage.getItem("username");
    this.userId = localStorage.getItem('userId')
  },
  updated() { },
  computed: {
    ...mapGetters({
      showGetMoreNotifications: 'app/isDisplayShowMoreNotifications'
    }),
    ...mapState("app", {
      navBarButtons: state => state.navBarButtons,
      notifications: state => state.notifications,
      badgeCount: state => state.badgeCount,
      notificationSound: state => state.notificationSound,
      newNotification: state => state.newNotification
    }),
    filteredNotifications() {
      return this.notifications.filter(notification => !notification.delete);
    },
    badgeCountComputed() {
      if (this.badgeCount >= 100) {
        return '99+'
      } else {
        return this.badgeCount
      }
    },
  },
  created() { },
  beforeDestroy() { },
  methods: {
    ...mapMutations('app', [
      'readNotification',
      'reduceBadgeCount',
      'deleteAllNotifications',
      'readAllNotifications',
      'setBadgeCount',
      'addNotifications'
    ]
    ),
    ...mapActions({
      openChangeEnterpriseDialog: "changeEnterprise/openChangeEnterpriseDialog",
      openReportBugDialog: "reportBug/openReportBugDialog",
      setFieldsRequired: "reportBug/setFieldsRequired",     
      openSecondFactorDialog: 'secondFactor/openSecondFactorDialog'     
    }),
    resetNavbarData() {
      this.logo = undefined;
      this.notificationMenu = false;
    },
    changeEnterprise() {
      this.openChangeEnterpriseDialog();
    },
    secondFactor () {      
      this.openSecondFactorDialog()
    },
    async logout() {
      await loginService.logout();
      this.resetNavbarData();
      this.$router.push("/login");
    },
    reportBug() {
      this.setFieldsRequired(true);
      this.openReportBugDialog();
    },
    getIcon(notification) {
      const menu = JSON.parse(localStorage.getItem('menu'))
      let subMenu = null
      let icon = null
      if (menu) {
        menu.forEach(m => {
          subMenu = m.subMenu.find(sm => sm.route === notification.url) ? m.subMenu.find(sm => sm.route === notification.url) : subMenu
        })
        icon = subMenu ? subMenu.icon : null
      }
      if (!icon) {
        switch (notification.url) {
          case '/charge-summary':
            icon = 'dashboard'
            break
          default:
            icon = FirebaseMessageConfig[notification.type].icon
        }
        return icon
      } else {
        return icon
      }
    },
    parseDateTime(dateTime) {
      const momentDate = moment(dateTime)
      return momentDate.format('DD/MM/YY-HH:mm:ss')
    },
    notificationClicked(notification) {
      if (!notification.show) {
        this.readNotificationAction(notification)
      }
      switch (notification.type) {
        case 'PRODUCTS_REPORT':
          this.$router.push({ path: notification.url, query: { key: notification.key } }).catch(() => { })
          break
      }
    },
    readNotificationAction(notification) {
      const notificationCopy = deepcopy(notification)
      notificationCopy.show = !notification.show
      messagingApi.update(notificationCopy)
      this.readNotification({ _id: notificationCopy._id, value: notificationCopy.show })
      if (notificationCopy.show) {
        this.reduceBadgeCount()
      } else {
        this.incrementBadgeCount()
      }
    },
    async deleteAll () {
      await messagingApi.deleteAll(this.userId)
      this.deleteAllNotifications({ value: true })
      await this.getAndSetBadgeCount()
    },
    async getAndSetBadgeCount () {
      if (this.userId) {
        const badgeConstResponse = await messagingApi.notReadCountForType(this.userId)
        this.setBadgeCount({ badgeCount: badgeConstResponse.data.count })
      }
    },
    async markAllAsRead () {
      await messagingApi.readAll(this.userId)
      this.readAllNotifications({ value: true })
      await this.getAndSetBadgeCount()
    },
    async getMoreNotifications () {
      if (this.userId) {
        await this.getAndSetBadgeCount()
        this.pageNumber++
        messagingApi.findAllForType(NOTIFICATIONS_PAGE_SIZE, this.pageNumber, this.userId).then(response => {
          this.addNotifications(response.data)
        })
      }
    },
  }
};
