/**
 * Tema por defecto
 */
export const ColvenTheme = {
  light: {
    primary: "#0694de",
    secondary: "#1976d2",
    accent: "#69CAFF",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFC107",
    turnedOff: "#000000",
    "icon-primary": "#0694de",
    "icon-secondary": "#1976d2"
  },
  dark: {
    primary: "#616161",
    secondary: "#455a64",
    accent: "#768C97",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFC107",
    "icon-primary": "#616161",
    "icon-secondary": "#455a64"
  }
};

/**
 * Estas constantes definen el tipo de restablecimiento de la contraseña:
 * 1- Restablecimiento por parte del usuario (RESET);
 * 2- Restablecimiento por fecha de expiración de la contraseña (EXPIRATION);
 * 3- Restablecimiento en el siguiente inicio de sesión (NEXT_LOGIN).
 */
export const ResetPasswordType = {
  USER_RESET: "RESET",
  EXPIRATION: "EXPIRATION_RESET",
  NEXT_LOGIN: "NEXT_LOGIN_RESET"
};

/**
 * Tamaños de página para las notificaciones y las alertas
 */
export const NOTIFICATIONS_PAGE_SIZE = 10;
export const ALERTS_PAGE_SIZE = 10;

/**
 * Constantes que definen el tipo de snackbar
 */
export const SnackbarStyle = {
  ERROR: "error",
  WARNING: "warning",
  SUCCESS: "done",
  INFO: "info"
};

/**
 * Constantes que definen el tipo de diálogo
 */
export const DialogType = {
  ERROR: "error",
  WARNING: "warning",
  SUCCESS: "thumb_up",
  INFO: "info",
  QUESTION: "contact_support"
};

export const NotificationType = {
  REPORT: {
    name: "REPORT",
    icon: "fa-list-alt",
    color: "#42A5F5"
  },
  PRODUCTS_REPORT: {
    name: "PRODUCTS_REPORT",
    icon: "fa-list-alt",
    color: "#42A5F5"
  },
  ERROR: {
    name: "ERROR",
    icon: "fa-exclamation-circle",
    color: "#F44336"
  },
  REMINDER: {
    name: "REMINDER",
    icon: "fa-sticky-note",
    color: "#47A34B"
  },
  ALERT: {
    name: "ALERT",
    icon: "fa-exclamation-triangle",
    color: "#FB8C00"
  }
};

/**
 * Constantes que definen el tipo de selección para los tabs donde se puedan elegir equipos O choferes
 */
export const filterTypes = {
  EQUIPMENTS: "EQUIPMENTS",
  DRIVERS: "DRIVERS"
};

/**
 * Constantes de los tipos de reportes, para guardar la configuración
 */
export const REPORT_TYPES = {
};

export const CONVERSIONS_LITERS = {
  litersPer100Km: "L",
  kmPerLiter: "L",
  milesPerGallon: "gal"
};
export const CONVERSIONS_DISTANCE = {
  litersPer100Km: "Km",
  kmPerLiter: "Km",
  milesPerGallon: "Mi",
};

export const FirebaseConfig = {
  apiKey: 'AIzaSyCxo22JHAeXAMQg7xRWzVWSrgDVXp4rjIw',
  authDomain: 'portal-agro-b6293.firebaseapp.com',
  databaseURL: 'https://portal-agro-b6293.firebaseio.com',
  projectId: 'portal-agro-b6293',
  storageBucket: 'portal-agro-b6293.appspot.com',
  messagingSenderId: '937829640157',
  appId: '1:937829640157:web:caccad46906b66c5a23834',
  measurementId: 'G-ZMRY5FD35E'
}

export const FirebaseMessageConfig = {
  PRODUCTS_REPORT: {
    icon: 'fa-list-alt',
    color: '#42A5F5'
  }
}

export const reportsColors = {
  PERFORMANCE: "#54c7ae",
  DISTANCE: "#e68c17",
  CONSUMPTION: "#eb3c10",
  DISCHARGES_EVEN: "#e88787",
  DISCHARGES_ODD: "#f50000",
  CHARGES_EVEN: "#bcf0bb",
  CHARGES_ODD: "#0fad0c",
  IDLING_EVEN: "#cdced1",
  IDLING_ODD: "#323236"
}

/**
 * KEYs de las configuraciones de los reportes
 */
export const CONFIGURATION_KEY = {
  PRODUCTS_ALERT_REPORT: 'PRODUCTS_ALERT_REPORT'
}

/**
 * TIPOS PARA EL REPORTE DE POSICIONES GENÉRICO
 * Por el momento solo está calibrador
 */
export const POSITIONS_TYPE = {
  CALIPER: "caliper"
}