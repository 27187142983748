/* eslint-disable indent */
import { reportBugService } from '@/business/reportBugService'
import { SnackbarStyle } from '@/constants/constants'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import i18n from '@/i18n'
import store from "@/store/store";

// nombre del app desde donde se reporta el bug
const APP_NAME = 'Gestya Portal Agro'

/**
 * Componente para reportar errores (tabla de errores reportados y dialog para reportar errores)
 */
export default {
    name: 'ReportBugComponent',
    components: {
    },
    data: () => ({
        // para validar el formulario
        validForm: false,
        // reglas de validación
        validationRules: {
            logo: (value) => !value || value.size < 2000000 || `${i18n.t('error.imageSize')}`,
            requiredText: (value) => (value != null && !!value.trim()) || `${i18n.t('required')}`,
            requiredImage: (value) => (value != null) || `${i18n.t('required')}`
        },
        // modelo de datos para reportar un bug
        bugModel: {
            screenshot: null,
            comment: null
        },
        // imagen seleccionada en el formulario
        imgSelected: null,
        // spinner
        loading: false
    }),
    computed: {
        /**
         * getters del store reportBug
         */
        ...mapGetters({
            'showDialog': 'reportBug/getShowDialog',
            'fieldsRquired': 'reportBug/getFieldsRequired',
            'autoScreenshot': 'reportBug/getAutoScreenshot',
            'getErrorMsg': 'reportBug/getErrorMsg',
            'getErrorRequestConfig': 'reportBug/getErrorRequestConfig',
            'getEmail': 'auth/getEmail',
            'getUserId': 'auth/getId',
            'getUsername': 'auth/getUsername'
        }),
        /**
         * Src de la captura de pantalla
         */
        logoSource () {
            if (this.bugModel.screenshot) {
                return 'data:image/png;base64,' + this.bugModel.screenshot
            }
        }
    },
    created () {
        /*
            Vuex watch del autoscreenshot
        */
        this.unwatchAutoScreenshot = this.$store.watch(
            (state, getters) => getters['reportBug/getAutoScreenshot'],
                (newValue) => {
                    if (newValue != null) {
                        this.bugModel.screenshot = newValue
                    }
                },
            { deep: true })
    },
    beforeDestroy () {
        this.unwatchAutoScreenshot()
    },
    mounted () {
    },
    methods: {
        /**
         * actions del store snackbar
         */
        ...mapActions('snackbar', [
            'showSnackbar',
            'closeSnackbar'
        ]),
        /**
         * actions de store reportBug
         */
        ...mapActions('reportBug', [
            'closeReportBugDialog',
            'resetState'
        ]),
        /**
         * Guardar
         */
        save () {
            this.loading = true
            const bugDto = this.getDto()
            reportBugService.sendEmail(bugDto).then(() => {
                this.showSnackbar({
                    visible: true,
                    text: this.$t('reportBug.successSendEmailMessage'),
                    timeout: 6000,
                    style: SnackbarStyle.SUCCESS
                })
                this.cancel()
                this.loading = false
            })
        },
        /**
         * Genera el DTO del reporte de bug
         */
        getDto () {
            const result = {
                userId: this.getUserId,
                username: this.getUsername,
                userEmail: this.getEmail,
                errorMsg: this.getErrorMsg,
                errorRequestConfig: this.getErrorRequestConfig,
                timestamp: moment().utc().format('DD/MM/YYY HH:mm:ss'),
                app: APP_NAME,
                lang: store.getters["user/getLocale"],
                ...this.bugModel
            }
            return result
        },
        /**
         * Si se hace click fuera del dialog, se ejecuta la misma función que el botón cancelar
         */
        clickOutside () {
            this.cancel()
        },
        /**
         * Botón cancelar
         */
        cancel () {
            this.closeReportBugDialog()
            this.bugModel = {
                screenshot: null,
                comment: null
            }
            this.imgSelected = null
            this.resetState()
            this.$refs.reportBugForm.resetValidation()
        },
        /**
         * Cuando selecciona la imágen, convierte a BASE64 y la asigna al model
         * @param {*} files
         */
        onFileSelected (file) {
            if (file) {
                // base 64 (para guardar en el modelo)
                const binaryReader = new FileReader()
                binaryReader.readAsBinaryString(file)
                const that = this
                binaryReader.onload = () => {
                    that.bugModel.screenshot = btoa(binaryReader.result)
                }
                binaryReader.onerror = () => {
                    console.error('Error al leer la imagen subida')
                }
            } else {
                this.bugModel.screenshot = null
            }
        },
        /**
         * Elimina la imagen seleccionada
         */
        deleteImg () {
            this.bugModel.screenshot = null
            this.imgSelected = null
        }
    }
}
