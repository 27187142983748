import http from "@/middleware";

const userApi = {
  async newUser(newUser, creatorUsername) {
    const body = { creatorUsername, newUser };
    const user = await http.post("api/user/create", body);
    return user;
  },
  async updateAlertConfiguration (alertConfig) {
    return http.put('/api/user/alert-configuration', alertConfig)
  }
};

export default userApi;
