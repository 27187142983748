const state = {
  username: localStorage.getItem("username"),
  email: localStorage.getItem("email"),
  token: localStorage.getItem("token"),
  resetPasswordJwt: undefined,
  remember: localStorage.getItem("remember") === "true",
  locale: localStorage.getItem("locale") || "es",
  userId: localStorage.getItem("userId"),
  admin: localStorage.getItem("admin") === "true",
  logo: localStorage.getItem("logo"),
  loginLogo: localStorage.getItem("loginLogo"),
  loginBackgroundImage: localStorage.getItem("loginBackgroundImage"),
  themes: JSON.parse(localStorage.getItem("themes")),
  privileges: [],
  enterpriseId: localStorage.getItem("enterpriseId"),
  enterpriseTimezone: localStorage.getItem("enterpriseTimezone"),
  internationalization: localStorage.getItem('internationalization')
};
const actions = {
  clearSessionData({ commit }) {
    commit("commitUserId", { userId: "" });
    commit("commitUsername", { username: "" });
    commit("commitEmail", { email: "" });
    commit("commitToken", { token: undefined });
    commit("commitAdmin", { admin: false });
    commit("commitPrivileges", { privileges: [] });
    commit("commitEnterpriseId", { enterpriseId: undefined });
    commit("commitEnterpriseTimezone", { enterpriseTimezone: undefined });
    commit('commitInternationalization', { internationalization: undefined })
    localStorage.removeItem("username");
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    localStorage.removeItem("admin");
    localStorage.removeItem("userConfiguration");
    localStorage.removeItem("privileges");
    localStorage.removeItem("enterpriseId");
    localStorage.removeItem("enterpriseTimezone");
    localStorage.removeItem('internationalization')
  },
  setInternationalization({ commit }, internationalization) {
    if (internationalization != null) {
      localStorage.setItem('internationalization', JSON.stringify(internationalization))
    } else {
      localStorage.removeItem('internationalization')
    }
    commit('commitInternationalization', { internationalization })
  },
  setEnterpriseTimezone({ commit }, enterpriseTimezone) {
    if (enterpriseTimezone != null) {
      localStorage.setItem("enterpriseTimezone", enterpriseTimezone);
    } else {
      localStorage.removeItem("enterpriseTimezone");
    }
    commit("commitEnterpriseTimezone", { enterpriseTimezone });
  },
  setUserId({ commit }, userId) {
    if (userId != null) {
      localStorage.setItem("userId", userId);
    } else {
      localStorage.removeItem("userId");
    }
    commit("commitUserId", { userId });
  },
  setUsername({ commit }, username) {
    if (username != null) {
      localStorage.setItem("username", username);
    } else {
      localStorage.removeItem("username");
    }
    commit("commitUsername", { username });
  },
  setEmail({ commit }, email) {
    if (email != null) {
      localStorage.setItem("email", email);
    } else {
      localStorage.removeItem("email");
    }
    commit("commitEmail", { email });
  },
  setResetPasswordJwt({ commit }, resetPasswordJwt) {
    commit("commitResetPasswordJwt", { resetPasswordJwt });
  },
  setRemember({ commit }, remember) {
    if (remember != null) {
      localStorage.setItem("remember", remember);
    } else {
      localStorage.removeItem("remember");
    }
    commit("commitRemember", { remember });
  },
  setLocale({ commit }, locale) {
    if (locale != null) {
      localStorage.setItem("locale", locale);
    } else {
      localStorage.removeItem("locale");
    }
    commit("commitLocale", { locale });
  },
  setLogo({ commit }, logo) {
    if (logo) {
      localStorage.setItem("logo", logo);
    } else {
      localStorage.removeItem("logo");
    }
    commit("commitLogo", { logo });
  },
  setLoginLogo({ commit }, loginLogo) {
    if (loginLogo) {
      localStorage.setItem("loginLogo", loginLogo);
    } else {
      localStorage.removeItem("loginLogo");
    }
    commit("commitLoginLogo", { loginLogo });
  },
  setLoginBackgroundImage({ commit }, loginBackgroundImage) {
    if (loginBackgroundImage) {
      localStorage.setItem("loginBackgroundImage", loginBackgroundImage);
    } else {
      localStorage.removeItem("loginBackgroundImage");
    }
    commit("commitLoginBackgroundImage", { loginBackgroundImage });
  },
  setThemes({ commit }, themes) {
    if (themes) {
      localStorage.setItem("themes", JSON.stringify(themes));
    } else {
      localStorage.removeItem("themes");
    }
    commit("commitThemes", { themes });
  },
  setPrivileges({ commit }, privileges) {
    if (privileges) {
      localStorage.setItem("privileges", JSON.stringify(privileges));
    } else {
      localStorage.removeItem("privileges");
      privileges = [];
    }
    commit("commitPrivileges", { privileges });
  },
  setAdmin({ commit }, admin) {
    localStorage.setItem("admin", admin);
    commit("commitAdmin", { admin });
  },
  setToken({ commit }, token) {
    localStorage.setItem("token", token);
    commit("commitToken", { token });
  },
  setEnterpriseId({ commit }, enterpriseId) {
    if (enterpriseId) {
      localStorage.setItem("enterpriseId", enterpriseId);
    } else {
      localStorage.removeItem("enterpriseId");
    }
    commit("commitEnterpriseId", { enterpriseId });
  },
};

const getters = {
  getUserId: (state) => {
    return state.userId;
  },
  getUsername: (state) => {
    return state.username;
  },
  getEmail: (state) => {
    return state.email;
  },
  getResetPasswordJwt: (state) => {
    return state.resetPasswordJwt;
  },
  getRemember: (state) => {
    return state.remember;
  },
  getLocale: (state) => {
    return state.locale;
  },
  getLogo: (state) => {
    return state.logo;
  },
  getLoginLogo: (state) => {
    return state.loginLogo;
  },
  getLoginBackgroundImage: (state) => {
    return state.loginBackgroundImage;
  },
  getThemes: (state) => {
    return state.themes;
  },
  getToken: () => {
    return state.token || localStorage.getItem("token");
  },
  getPrivileges: (state) => {
    return (
      (state.privileges.length > 0 && state.privileges) ||
      JSON.parse(localStorage.getItem("privileges"))
    );
  },
  getAdmin: (state) => {
    return state.admin || JSON.parse(localStorage.getItem("admin"));
  },
  getEnterpriseId: () => {
    return state.enterpriseId;
  },
  getEnterpriseTimezone: (state) => {
    return (
      state.enterpriseTimezone ||
      JSON.parse(localStorage.getItem("enterpriseTimezone"))
    );
  },

  getInternationalization: (state) => {
      if (state.internationalization && typeof state.internationalization === "string") return  JSON.parse(state.internationalization)
        return  state.internationalization || JSON.parse(localStorage.getItem('internationalization'))
  },
};

const mutations = {
  commitUserId(state, { userId }) {
    state.userId = userId;
  },
  commitUsername(state, { username }) {
    state.username = username;
  },
  commitEmail(state, { email }) {
    state.email = email;
  },
  commitResetPasswordJwt(state, { resetPasswordJwt }) {
    state.resetPasswordJwt = resetPasswordJwt;
  },
  commitRemember(state, { remember }) {
    state.remember = remember;
  },
  commitLocale(state, { locale }) {
    state.locale = locale;
  },
  commitLogo(state, { logo }) {
    state.logo = logo;
  },
  commitLoginLogo(state, { loginLogo }) {
    state.loginLogo = loginLogo;
  },
  commitLoginBackgroundImage(state, { loginBackgroundImage }) {
    state.loginBackgroundImage = loginBackgroundImage;
  },
  commitThemes(state, { themes }) {
    state.themes = themes;
  },
  commitToken(state, { token }) {
    state.token = token;
  },
  commitPrivileges(state, { privileges }) {
    state.privileges = privileges;
  },
  commitAdmin(state, { admin }) {
    state.admin = admin;
  },
  commitEnterpriseId(state, { enterpriseId }) {
    state.enterpriseId = enterpriseId;
  },
  commitEnterpriseTimezone(state, { enterpriseTimezone }) {
    state.enterpriseTimezone = enterpriseTimezone;
  },
  commitInternationalization(state, { internationalization }) {
    state.internationalization = internationalization;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
