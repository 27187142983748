export const navBarService = (function () {
  "use strict";

  const navBarItems = [
    // {
    //   id: "toolbar_AlertsBtn",
    //   title: "navbar.alerts",
    //   route: "/alerts",
    //   keys: { and: ["products.alerts"] }
    // },
    // {
    //   id: "toolbar_reportsBtn",
    //   title: "navbar.reports",
    //   subMenu: [
    //     {
    //       title: "navbar.performanceSummary",
    //       icon: 'dashboard',
    //       route: "/performance-summary"
    //     }
    //   ]
    // }

    {
      id: "caliperSummary",
      title: "navbar.caliper",
      subMenu: [
        {
          title: "navbar.caliperSummary",
          icon: 'dashboard',
          route: "/caliperSummary"
        },
        {
          title: "navbar.caliperDetails",
          icon: 'dashboard',
          route: "/caliperDetails"
        }
      ]
    },
    {
      id: "toolbar_AlertsBtn",
      title: "navbar.alerts",
      route: "/alerts",
      // keys: { and: ["products.alerts"] }
    },
    {
      id: "toolbar_PositionsBtn",
      title: "navbar.positions.title",
      subMenu: [
        {
          title: "navbar.positions.caliper",
          icon: 'dashboard',
          route: "/positions/caliper"
        }
      ],
      //keys: { and: ["products.positions"] } TODO: AGREGAR PERMISO
    }
  ];

  function getMenuByUser() {
    // navBarItems
    let permissions = getPermissions();
    let admin = JSON.parse(localStorage.getItem("admin"));
    const navBarItemsTemp =
      JSON.parse(localStorage.getItem("menu")) || navBarItems;
    let result = [];
    navBarItemsTemp.forEach(navBarItem => {
      if (admin || validatePermissions(permissions, navBarItem)) {
        if (navBarItem.subMenu) {
          const items = [];
          const mainMenu = Object.assign({}, navBarItem);
          navBarItem.subMenu.forEach(subMenuItem => {
            if (admin || validatePermissions(permissions, subMenuItem)) {
              items.push(subMenuItem);
            }
          });
          mainMenu.subMenu = items;
          result.push(mainMenu);
        } else {
          result.push(navBarItem);
        }
      }
    });
    return result;
  }

  function validatePermissions(permissions, item) {
    if (item.keys) {
      if (item.keys.and) {
        const conditions = item.keys.and;
        let result = true;
        conditions.forEach(condition => {
          if (!permissions.includes(condition)) {
            result = result && false;
          }
        });
        return result;
      } else if (item.keys.or) {
        const conditions = item.keys.or;
        let result = false;
        conditions.forEach(condition => {
          if (permissions.includes(condition)) {
            result = result || true;
          }
        });
        return result;
      }
      return false;
    }
    return true;
  }

  function getPermissions() {
    return localStorage.getItem("privileges")
      ? JSON.parse(localStorage.getItem("privileges"))
      : [];
  }

  return {
    getMenuByUser
  };
})();
