import { mapActions, mapState, mapMutations } from "vuex";

export default {
  // Nombre del componente
  name: "DialogComponent",
  data() {
    // Datos del componente
    return {};
  },
  mounted() {
    // Cuando se presiona la tecla Escape, se ejecuta el método para cerrar el diálogo
    this.$el.addEventListener("keyup", e => {
      if (e.keyCode === 27) {
        this.close();
      }
    });
  },
  computed: {
    ...mapState("dialog", {
      text: state => state.text,
      title: state => state.title,
      type: state => state.type,
      color: state => state.color,
      overlayOpacity: state => state.overlayOpacity,
      visible: state => state.visible,
      actionButtons: state => state.actionButtons,
      yesAction: state => state.yesAction,
      noAction: state => state.noAction
    })
  },
  created() {},
  beforeDestroy() {},
  methods: {
    ...mapActions(" dialog", [
      "setText",
      "setTitle",
      "setType",
      "setColor",
      "setVisible",
      "setActionButtons"
    ]),
    ...mapMutations("dialog", ["closeDialog"]),
    yesDialogAction() {
      // Evento que se envía al componente padre cuando el usuario presiona el botón de acción afirmativa
      if (this.yesAction) {
        this.yesAction();
      }
      this.closeDialog();
    },
    noDialogAction() {
      // Evento que se envía al componente padre cuando el usuario presiona el botón de acción negativa
      if (this.noAction) {
        this.noAction();
      }
      this.closeDialog();
    },
    close() {
      this.closeDialog();
    },
    clickOutside() {
      this.close();
    }
  }
};
